<template>
  <b-row class="sub-table">
    <b-col>
      <!--상품 -->
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">상품별</h5>
        </div>
        <div class="card-body p-0">
          <table class="table table-sm no-border">
            <tbody>
              <tr v-for="(ele, idx) in totalProd" :key="idx">
                <th v-if="ele.pr_type == 99">
                  <h5><span class="badge bg-danger">총합</span></h5>
                </th>
                <th v-else>
                  {{ ele.pr_type_nm }}
                </th>
                <td>{{ ele.count }} 건</td>
                <td>{{ ele.price | commaFilter }} 원</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> </b-col
    ><b-col>
      <!-- 결제수단 -->
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">결제수단별</h5>
        </div>
        <div class="card-body p-0">
          <table class="table table-sm no-border">
            <tbody>
              <tr v-for="(ele, idx) in total" :key="idx">
                <th v-if="ele.ord_way_cd == 99">
                  <h5><span class="badge bg-danger">총합</span></h5>
                </th>
                <th v-else>
                  {{ ele.ord_way_nm }}
                </th>
                <td>{{ ele.count }} 건</td>
                <td>{{ ele.price | commaFilter }} 원</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'dayTotal',
  props: ['total', 'totalProd'],
  data() {
    return {
      isBusy: false
    }
  }
}
</script>

<style coped>
.sub-table td {
  text-align: right;
}
</style>
